import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { DEFAULT_ZIPCODE } from '../constants/default';
import { STARTTIMELA, STARTTIMESF } from '../constants/deliveryMessageTiming';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import appContext from '../Context/appContext';
import { convertEstimatedTimeToLocal } from '../helpers/MapHelper';
import { splitIntoDateTimeComponents } from '../helpers/timeHelpers';
import { useDeliveryDetails, useHexAsapDeliveryDetails } from '../hooks/app';
import RemainingTime from './ASAPRemainingTime';

export default function DeliveryTiming({ currentTab, showNextWindow, setShowNextWindow, onlyText }) {
  const { deliveryDetails: { postcode = DEFAULT_ZIPCODE, latitude, longitude } } = useSelector(state => state.user)

  const {
    data: {
      locationId,
      avgDeliveryTime,
      deliveryThreshold,
      isHexLocation,
      asapStartTime,
      asapEndTime,
      asapReOpensAt,
      asapTimer
    }
  } = useDeliveryDetails(false, true);


  const { data: hexAsapDeliveryDetails } = useHexAsapDeliveryDetails(locationId, [postcode, latitude, longitude], {
    refreshWhenHidden: false,
    refreshInterval: 0,
    revalidateOnMount: true,
    revalidateOnFocus: true
  });

  const interval = useRef(null);
  const [remainingTime, setRemainingTime] = useState(-1);
  const timeLeft = asapTimer;
  const remainingHours = parseInt(remainingTime / 3600);
  const remainingSeconds = remainingTime % 60;
  const remainingMinutes = parseInt(remainingTime / 60) % 60;

  let showLAMessage = true;
  let showSFMessage = false;

  const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });


  if (locationId === 1 && currentTime > STARTTIMELA) {
    showLAMessage = true;
  } else if (locationId === 2 && currentTime > STARTTIMESF) {
    showSFMessage = true;
  }

  const newAsapDeliveryDetails = {
    newAsapDeliveryWindow: hexAsapDeliveryDetails?.deliveryDetails?.asap_standard_delivery_window || '',
    newAsapPriorityDeliveryThreshold: hexAsapDeliveryDetails?.deliveryDetails?.asap_priority_delivery_threshold || '',
    newAsapPriorityDeliveryFee: hexAsapDeliveryDetails?.deliveryDetails?.asap_priority_delivery_fee || ''
  };

  const { newAsapDeliveryWindow, newAsapPriorityDeliveryThreshold } = newAsapDeliveryDetails || {};

  let asapStandardDeliveryTime = '';
  let asapPriorityDeliveryTime = '';

  if (currentTab === ASAP && currentTime >= asapStartTime && currentTime <= asapEndTime) {
    if (isHexLocation) {
      asapStandardDeliveryTime = newAsapDeliveryWindow || '';
      asapPriorityDeliveryTime = deliveryThreshold ? newAsapPriorityDeliveryThreshold || '' : '';
    } else if (showLAMessage || showSFMessage) {
      asapStandardDeliveryTime = avgDeliveryTime || '';
      asapPriorityDeliveryTime = deliveryThreshold || '';
    }
  }

  const clearIntervals = () => {
    clearInterval(interval.current);
  };

  const renderMinutes = () => {
    let text = '';

    if (remainingMinutes) {
      text = `${remainingMinutes}m : `;
    } else if (remainingHours) {
      text = '0m:';
    }
    return text;
  };

  const renderSeconds = () => {
    let text = '';
    if (remainingSeconds) {
      if (remainingSeconds < 10) {
        text = `0${remainingSeconds}s`;
      } else {
        text = `${remainingSeconds}s`;
      }
    } else if (remainingMinutes) {
      text = '00s';
    }
    return text;
  };

  function renderCurrentTime(minutesToAdd) {
    if (minutesToAdd) {
      // round off the to the next 5th minute
      const factor = 1000 * 60 * 5;
      const dateObj = convertEstimatedTimeToLocal(minutesToAdd, true);
      const millis = dateObj.getTime();

      if (dateObj.getMinutes() % 5 === 0) {
        return splitIntoDateTimeComponents(dateObj, true).time12;
      }
      return splitIntoDateTimeComponents(Math.ceil(millis / factor) * factor, true).time12;
    }
    return '';
  }

  useEffect(() => {
    const { hours = 0, minutes = 0, seconds = 0 } = timeLeft || {};
    if (Object.keys(asapTimer).length) {
      setRemainingTime(hours * 3600 + minutes * 60 + seconds);
    }
  }, [timeLeft]);

  useEffect(() => {
    const calculateAndSetTime = () => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else if (remainingTime == 0) {
        setRemainingTime(-1)
      }
    };

    interval.current = setInterval(() => {
      calculateAndSetTime();
    }, 1000);

    return () => {
      clearIntervals();
    };
  }, [remainingTime]);

  function renderDeliveryTime() {
    let standardTime = '';
    let priorityTime = '';
    if (asapPriorityDeliveryTime) {
      if (isHexLocation) {
        priorityTime = renderCurrentTime(asapPriorityDeliveryTime);
      } else if (asapPriorityDeliveryTime) {
        priorityTime = `${asapPriorityDeliveryTime} mins`;
      }
    }
    if (asapStandardDeliveryTime) {
      if (isHexLocation) {
        const standardTimeSplit = asapStandardDeliveryTime.split(' - ');
        standardTime = onlyText ? `${standardTimeSplit.toString().replace(",", "-")} mins` : `${renderCurrentTime(Number(standardTimeSplit[0]))} - ${renderCurrentTime(Number(standardTimeSplit[1]))}`;
      } else if (asapStandardDeliveryTime) {
        standardTime = `${asapStandardDeliveryTime} mins`;
      }
    }

    if (standardTime || asapPriorityDeliveryTime) {
      if (onlyText && (standardTime || (asapTimer && Object.keys(asapTimer).length && remainingHours < 1))) {
        return (asapTimer && Object.keys(asapTimer).length && remainingHours < 1) ? `ASAP Closes in ${renderMinutes()}${renderSeconds()}` : `Get in ${standardTime}`
      }
      return (
        <div className='delivery-message-wrapper'>
          <div className="delivery-timing-new">
            <div className='asap-on'>
              <p className={`m-0 standard ${standardTime ? "" : "d-none"}`}><i className="icon-Asap-Priority"></i>Standard: <span className='standard-time'> {standardTime}</span></p>
              <p className={`m-0 priority ${priorityTime ? "" : "d-none"}`}><i className="icon-Asap-Standard"></i>Priority: <span className='priority-time'> {priorityTime}</span></p>
            </div>
            {(asapTimer && Object.keys(asapTimer).length && remainingHours < 1) ? <div className="asap-remaining">
              <p className='text-center'>ASAP Closes in <span>{renderMinutes()}{renderSeconds()}</span></p>
            </div> : null}
          </div>
          <button onClick={() => setShowNextWindow(!showNextWindow)} className='btn icon-close'></button>
        </div>
      );
    } else if (onlyText) return 'Get in 45 mins'
    return null;
  }

  if (asapReOpensAt) {
    return null
  }

  return (
    onlyText ?
      renderDeliveryTime()
      :
      <div>{renderDeliveryTime()}</div>

  )
}
